import React, { useState } from 'react';
import './GetSuggestions.css';

const GetSuggestions = () => {
  const [completion, setCompletion] = useState('');
  const [prompt, setPrompt] = useState('');

  async function fetchApi(prompt) {
    const response = await fetch('https://api.openai.com/v1/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer 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',
      },
      body: JSON.stringify({
        model: 'text-davinci-002',
        prompt: `If a student is struggling to: ${prompt}\n\nThen 4 things a teacher can do are:`,
        temperature: 0.7,
        max_tokens: 1694,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      }),
    });
    const json = await response.json();
    setCompletion(json.choices[0].text);
  }

  const onFormSubmit = event => {
    event.preventDefault();
    fetchApi(prompt);
  };

  const onChange = event => {
    setPrompt(event.target.value);
  };

  return (
    <div className="student-insights">
      <h1>Artificial Intelligence Student Insights</h1>
      <form onSubmit={onFormSubmit}>
        <label>What is your student struggling with?</label>
        <input type="text" value={prompt} onChange={onChange} />
        <input type="submit" value="Submit" />
      </form>
      <div id="completion">{completion}</div>
    </div>
  );
};

export default GetSuggestions;
