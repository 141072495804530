import logo from './logo.svg';
import './App.css';
import GetSuggestions from './Components/GetSuggestions';

function App() {
  return (
    <div className="App">
      <GetSuggestions />
    </div>
  );
}

export default App;
